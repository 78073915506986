
import { defineComponent, onMounted, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";

interface ProfileDetails {
	//avatar: string;
	name: string;
	lastName: string;
	email: string;
	division_id: string;
	job_role: string;
	phone: string;
}
interface PasswordDetails {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

interface AccountSettings {
	enableUser: boolean;
}

export default defineComponent({
	name: "EditProfile",
	components: {
		ErrorMessage,
		Field,
		Form,
	},
	props: {
		userId: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const store = useStore();

		const submitButtonProfileDetails = ref<HTMLElement | null>(null);
		const updatePasswordButton = ref<HTMLElement | null>(null);

		const submitButton1 = ref<HTMLElement | null>(null);
		const submitButton5 = ref<HTMLElement | null>(null);
		
		const passwordFormDisplay = ref(false);
		const user = ref(null);
		const divisionLists = ref([]);

		const profileDetailsValidator = Yup.object().shape({
			name: Yup.string().required().label("First name"),
			last_name: Yup.string().required().label("Last name"),
			email: Yup.string().required().email(),
			division_id: Yup.string().required().label("Division"),
			phone: Yup.string().min(5).max(50).required().label("Phone"),
		});

		const changePasswordValidator = Yup.object().shape({
			currentpassword: Yup.string().min(8).required().label("Current password"),
			newpassword: Yup.string().min(8).required().label("Password"),
			confirmpassword: Yup.string()
				.min(8)
				.required()
				.oneOf([Yup.ref("newpassword"), null], "Passwords must match")
				.label("Password Confirmation"),
		});

		const profileDetails = ref<ProfileDetails>({
			//avatar: "/media/avatars/150-2.jpg",
			name: "",
			lastName: "",
			email: "",
			division_id: "",
			job_role: "",
			phone: "",
		});

		const passwordDetails = ref<PasswordDetails>({
			currentPassword: "",
			newPassword: "",
			confirmPassword: "",
		});

		const AccountSettings = ref<AccountSettings>({
			enableUser: true,
		});

		const showAlert = (icon, message) =>  {
			Swal.fire({
				text: message,
				icon: icon,
				confirmButtonText: "Ok",
				buttonsStyling: false,
				customClass: {
					confirmButton: "btn btn-light-primary",
				},
			});
		};

		const saveProfileDetails = async (values) => {
			if (submitButtonProfileDetails.value) {
				// Activate indicator
				submitButtonProfileDetails.value.setAttribute("data-kt-indicator", "on");

				values.user_id = props.userId;

				ApiService.setHeader();
				const resp = await ApiService.post("user/profile/update", values)
					.then(({ data }) => {
						if (data.code == 426) {
							showAlert('error', 'Email already used');
						}else{
							showAlert('success', 'Successfully updated profile');
						}
						submitButtonProfileDetails.value?.removeAttribute("data-kt-indicator");
						return true;
					})
					.catch(({ response }) => {
						//console.log(response);
					});
			}
		};

		const updatePassword = async (values) => {
			if (updatePasswordButton.value) {
				// Activate indicator
				updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

				values.user_id = props.userId;

				ApiService.setHeader();
				const resp = await ApiService.post("user/password/update", values)
					.then(({ data }) => {
						updatePasswordButton.value?.removeAttribute("data-kt-indicator");

						if (data.code == 427) {
							showAlert('error', 'Current password does not match');
						}else{
							showAlert('success', 'Successfully updated password');
						}
						return true;
					})
					.catch(({ response }) => {
						//console.log(response);
					});
				
			}
		};

		const deactivateAccount = () => {
			if (submitButton5.value) {
				// Activate indicator
				submitButton5.value.setAttribute("data-kt-indicator", "on");

				setTimeout(() => {
					submitButton5.value?.removeAttribute("data-kt-indicator");

					Swal.fire({
						text: "Email is successfully changed!",
						icon: "success",
						confirmButtonText: "Ok",
						buttonsStyling: false,
						customClass: {
							confirmButton: "btn btn-light-primary",
						},
					}).then(() => {
						//emailFormDisplay.value = false;
					});
				}, 2000);
			}
		};

		const removeImage = () => {
			//profileDetails.value.avatar = "media/avatars/blank.png";
		};

		onMounted(async () => {
			setCurrentPageBreadcrumbs("Settings", ["Account"]);

			// DIVISION LIST
			const division = await store.dispatch(Actions.DIVISION_LIST);
			if (division != undefined && division.status && division.data.length > 0) {
				divisionLists.value = division.data;
			}
			// USER
			let userProfile = await store.dispatch(Actions.USER_PROFILE, { user_id: props.userId });
			if (userProfile != undefined && Object.prototype.hasOwnProperty.call(userProfile, "code")  && userProfile.code == 200) {
				user.value = userProfile.data;

				profileDetails.value.name = userProfile.data.name;
				profileDetails.value.lastName = userProfile.data.last_name;
				profileDetails.value.email = userProfile.data.email;
				profileDetails.value.phone = userProfile.data.phone;
				// UPDATE DIVISION NAME
				//mp: if (userProfile.data.hasOwnProperty("job") && userProfile.data.job.hasOwnProperty("division_id")) {
				if (Object.prototype.hasOwnProperty.call(userProfile.data, "job") && Object.prototype.hasOwnProperty.call(userProfile.data.job, "division_id")) {
					profileDetails.value.division_id = userProfile.data.job.division_id;
				}

				if (Object.prototype.hasOwnProperty.call(userProfile.data.job, "role")) {
					profileDetails.value.job_role = userProfile.data.job.role;
				}
			}
		});

		// COMPUTED
		const isAdmin = computed(() => store.getters["userRole"] === 1);

		return {
			submitButtonProfileDetails,
			submitButton1,
			saveProfileDetails,
			deactivateAccount,
			profileDetails,
			passwordDetails,
			AccountSettings,
			passwordFormDisplay,
			removeImage,
			profileDetailsValidator,
			changePasswordValidator,
			updatePasswordButton,
			updatePassword,
			user,
			divisionLists,
			isAdmin,
		};
	},
});
